import CreateBrandDomainPage from 'containers/WorkSpace/BrandDomain/CreateBrandDomain';
import { BrandDomainPage } from 'containers/WorkSpace/BrandDomain/Loadable';
import { CategoryPage } from 'containers/WorkSpace/Category/Loadable';
import { CertificationPage } from 'containers/WorkSpace/Certification/Loadable';
import { CustomDomainPage } from 'containers/WorkSpace/CustomDomain/Loadable';
import { DashboardPage } from 'containers/WorkSpace/Dashboard/Loadable';
import { EntityDetailPage } from 'containers/WorkSpace/Entity/DetailPage/Loadble';
import { EntityPage } from 'containers/WorkSpace/Entity/ListPage/Loadable';
import { OrganizationPage } from 'containers/WorkSpace/Organization/Loadable';
import { ProductDetailPage } from 'containers/WorkSpace/Product/DetailPage/Loadable';
import { ProductPage } from 'containers/WorkSpace/Product/ListPage/Loadable';
import { ProductUnitPage } from 'containers/WorkSpace/ProductUnit/Loadable';
import { TransactionPage } from 'containers/WorkSpace/Transaction/Loadable';
import { UserSMSAccountPage } from 'containers/WorkSpace/UserSMSAccount/Loadable';
import { UserSubscriptionPage } from 'containers/WorkSpace/UserSubscription/Loadable';

export interface RouteProps {
  path?: string;
  component: any;
  render?: (props: any) => void;
  exact?: boolean;
}

export const routes = (): RouteProps[] => [
  {
    exact: true,
    path: `/`,
    component: DashboardPage,
  },
  {
    exact: true,
    path: `/system/unit`,
    component: ProductUnitPage,
  },
  {
    exact: true,
    path: `/system/category`,
    component: CategoryPage,
  },
  {
    exact: true,
    path: `/system/organization`,
    component: OrganizationPage,
  },
  {
    exact: true,
    path: `/censorship/entity/list`,
    component: EntityPage,
  },
  {
    exact: true,
    path: `/censorship/entity/:id/detail`,
    component: EntityDetailPage,
  },
  {
    exact: true,
    path: `/censorship/product/list`,
    component: ProductPage,
  },
  {
    exact: true,
    path: `/censorship/product/:id/detail`,
    component: ProductDetailPage,
  },
  {
    exact: true,
    path: `/censorship/certification/list`,
    component: CertificationPage,
  },
  {
    exact: true,
    path: `/advanced/custom-domain`,
    component: CustomDomainPage,
  },
  {
    exact: true,
    path: `/advanced/sms`,
    component: UserSMSAccountPage,
  },
  {
    exact: true,
    path: `/advanced/brand-domain`,
    component: BrandDomainPage,
  },
  {
    exact: true,
    path: `/advanced/brand-domain/create`,
    component: CreateBrandDomainPage,
  },
  {
    exact: true,
    path: `/advanced/subscription`,
    component: UserSubscriptionPage,
  },
  {
    exact: true,
    path: `/statistical`,
    component: DashboardPage,
  },
  {
    exact: true,
    path: `/transaction`,
    component: TransactionPage,
  },

  {
    path: `/partner/customer`,
    component: DashboardPage,
  },
  {
    path: `/partner/supplier`,
    component: DashboardPage,
  },
];
