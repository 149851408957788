import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ProductUnitPrefixAPI } from 'api';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { LOADING_STATUS } from 'utils/constants';

export const findProductUnitPrefixes = createAsyncThunk<any, any>(
  'productUnitPrefix/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await ProductUnitPrefixAPI.filter(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const checkExistProductUnitPrefix = createAsyncThunk<any, any>(
  'productUnitPrefix/exists',
  async (params, thunkAPI) => {
    try {
      const response = await ProductUnitPrefixAPI.checkExists(params?.name);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const createProductUnitPrefix = createAsyncThunk(
  'productUnitPrefix/createProductUnitPrefix',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await ProductUnitPrefixAPI.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeProductUnitPrefix = createAsyncThunk(
  'productUnitPrefix/removeProductUnitPrefix',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await ProductUnitPrefixAPI.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateProductUnitPrefix = createAsyncThunk(
  'category/updateProductUnitPrefix',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await ProductUnitPrefixAPI.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
  isVisibleCreateModal: false,
} as any;

const productUnitPrefixSlice = createSlice({
  name: 'productUnitPrefix',
  initialState,
  reducers: {
    toggleCreateProductUnitPrefixModal: state => {
      state.isVisibleCreateModal = !state.isVisibleCreateModal;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findProductUnitPrefixes.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findProductUnitPrefixes.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(
        findProductUnitPrefixes.rejected,
        (state, { payload: error }) => {
          state.loading = LOADING_STATUS.rejected;
          state.error = error;
        }
      );
  },
});

export const { toggleCreateProductUnitPrefixModal } =
  productUnitPrefixSlice.actions;

export default productUnitPrefixSlice.reducer;
