import React from 'react';

import { IconProps } from './Icon';

export const UnitIcon = ({
  width,
  height,
  className,
  color,
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="_16" data-name="16">
      <path d="m27 2h-22a3.0033 3.0033 0 0 0 -3 3v22a3.0033 3.0033 0 0 0 3 3h22a3.0033 3.0033 0 0 0 3-3v-22a3.0033 3.0033 0 0 0 -3-3zm-6 2v4a1.0009 1.0009 0 0 1 -1 1h-3v-2a1 1 0 0 0 -2 0v2h-3a1.0009 1.0009 0 0 1 -1-1v-4zm7 23a1.0009 1.0009 0 0 1 -1 1h-22a1.0009 1.0009 0 0 1 -1-1v-22a1.0009 1.0009 0 0 1 1-1h4v4a3.0033 3.0033 0 0 0 3 3h8a3.0033 3.0033 0 0 0 3-3v-4h4a1.0009 1.0009 0 0 1 1 1z" />
    </g>
  </svg>
);
